<template>
  <div class="category-container align-items-center">
    <button
      class="category-btn d-flex align-items-center"
      :class="[active === 'favorites' && 'active']"
      @click="setActive('favorites')"
    >
      <b-icon class="heart" icon="heart-fill"></b-icon>
      favorites
    </button>
    <button
      class="category-btn d-flex align-items-center"
      :class="[active === 'recents' && 'active']"
      @click="setActive('recents')"
    >
      <img src="./../assets/envelope-one.svg" />
      recents
    </button>
    <div class="category-header">
      <span>Categories</span>
    </div>
    <ul class="categories">
      <template v-for="item in scrollableItems">
        <li
          v-if="!item.disabled"
          :class="[checkFilter(item.id) && 'active']"
          class="categories-list"
          :key="item.id"
        >
          <input
            :id="item.id"
            type="checkbox"
            class="category"
            :disabled="item.disabled"
            :value="item.id"
            v-model="selected"
            @change="setActive(item.id)"
          />
          <span class="checkbox"> </span>
          <label :for="item.id">
            {{
              activeLanguage === 'es' && item.spanishName
                ? item.spanishName
                : item.name
            }}
          </label>
        </li>
      </template>
    </ul>
    <button
      class="reset align-items-center justify-content-center"
      @click="setActive('root')"
    >
      reset
    </button>

    <button
      class="phone-filter align-items-center justify-content-center"
      @click="showPhoneFilter = !showPhoneFilter"
    >
      Filter

      <ul class="categories filter-dropdown" :class="showPhoneFilter && 'show'">
        <template v-for="item in scrollableItems">
          <li v-if="!item.disabled" class="categories-list" :key="item.id">
            <input
              :id="item.id"
              type="checkbox"
              class="category"
              :disabled="item.disabled"
              :value="item.id"
              v-model="selected"
              @change="setActive(item.id)"
            />
            <span class="checkbox"> </span>
            <label :for="item.id">
              {{
                activeLanguage === 'es' && item.spanishName
                  ? item.spanishName
                  : item.name
              }}
            </label>
          </li>
        </template>
      </ul>
    </button>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'CategoryFilter',
  props: ['items', 'active'],
  data() {
    return {
      selected: [],
      showPhoneFilter: false,
    };
  },
  methods: {
    setActive(id) {
      let scrollableList = this.scrollableItems.map((i) => i.id);
      let changeIds = id;
      if (this.active === id) {
        changeIds = 'root';
      }
      if (scrollableList.includes(id)) {
        changeIds = this.selected;
      } else {
        this.selected = [];
      }
      if (
        scrollableList.includes(id) &&
        this.selected.length === 0 &&
        changeIds !== 'root'
      ) {
        changeIds = 'root';
      }
      this.$emit('change', changeIds);
    },
    checkFilter(id) {
      if (this.active === 'root') return false;
      return this.selected.includes(id);
    },
  },
  computed: {
    ...mapState('front', ['activeLanguage']),
    scrollableItems() {
      let list = this.items.filter(
        (item) =>
          item.id !== 'favorites' &&
          item.id !== this.$store.state.front.defaultCategory
      );
      return list;
    },
  },
};
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import '../scss/variables';

.category-container {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  width: clamp(180px, 26%, 260px);
  padding-top: 1.6rem;
  padding-bottom: 1.2rem;

  @media (max-width: $phone-width) {
    display: flex;
    flex-direction: row;
    padding-top: 1.2rem;
    padding-bottom: 0;
  }
}

.category-header {
  border-bottom: 1px solid #00000020;
  font: $font-size-18;
  color: $font-base;
  width: -webkit-fill-available;
  padding-bottom: 0.4rem;
  padding-top: 1.8rem;

  @media (max-width: $phone-width) {
    display: none;
  }
}

.categories {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  list-style-type: none;
  padding: 0.4rem 0;
  width: -webkit-fill-available;
  overflow: hidden;
  min-height: max-content;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (max-width: $phone-width) {
    display: none;
  }

  .categories-list {
    display: flex;

    font: $font-size-16;
    color: $font-base;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    text-align: left;
    width: unset;
    border: unset;
    box-shadow: unset;
    padding: 0;
    position: relative;
    cursor: pointer;

    label {
      margin: 0;
      cursor: pointer;
    }
    input {
      visibility: hidden;
      opacity: 0;
      cursor: pointer;
      z-index: -1;
    }

    ::before {
      content: '';
      position: absolute;
      top: 2.4px;
      left: 0;
      height: 15px;
      width: 15px;
      border: 0.75px solid $border;
      background-color: $base-checkbox;
      border-radius: 2px;
      cursor: pointer;
    }

    &.active ::before {
      background-color: $highlight2;
    }
  }

  // .categories-item {
  //   font: $font-size-16;
  //   color: $font-base;
  //   background-color: transparent;
  //   display: flex;
  //   align-items: center;
  //   gap: 0.6rem;
  //   text-align: left;
  //   width: unset;
  //   border: unset;
  //   box-shadow: unset;
  //   padding: 0;
  //   position: relative;
  //   cursor: pointer;

  //   &.active {
  //     font-weight: 600;
  //     color: $highlight2;

  //     input:checked ~ .checkbox {
  //       background-color: $highlight;
  //     }
  //   }

  //   button {
  //     cursor: pointer;

  //     @media (max-width: $phone-width) {
  //       display: flex;
  //       flex-direction: row;
  //     }
  //   }

  //   .hidden {
  //     visibility: hidden;
  //   }

  //   input {
  //     visibility: hidden;
  //     opacity: 0;
  //     cursor: pointer;
  //     z-index: -1;
  //   }

  //   .checkbox {
  //     position: absolute;
  //     position: absolute;
  //     top: 2.4px;
  //     left: 0;
  //     height: 15px;
  //     width: 15px;
  //     border: 0.75px solid $border;
  //     background-color: $base-checkbox;
  //     border-radius: 2px;
  //   }
  // }
}

.category-btn {
  border: 1px solid $border;
  border-radius: 2px;
  background-color: $base-button;
  width: -webkit-fill-available;
  padding: 0.6rem 0 0.6rem 0.8rem;
  gap: 0.6rem;
  font: $font-size-16;
  text-transform: capitalize;
  color: $font-base;

  @media (max-width: $phone-width) {
    padding: 0.6rem 0.8rem 0.6rem 0.8rem;
  }

  &.active {
    background-color: $highlight;
    color: #373534;
    font-weight: bold;
  }

  &:first-child {
    border-bottom: 0;

    @media (max-width: $phone-width) {
      border-bottom: 1px solid $base-grey;
      border-right: 0;
    }
  }

  .heart {
    color: $base-pink;
    width: 22px;
  }
}

.reset {
  display: flex;
  border: 1px solid $border;
  border-radius: 2px;
  text-transform: uppercase;
  background-color: $base2-button;
  width: -webkit-fill-available;
  padding: 0.4rem 0;
  gap: 0.6rem;
  font: $font-size-18;

  @media (max-width: $phone-width) {
    display: none;
  }
}

.phone-filter {
  display: none;
  border: 1px solid $base-yellow;
  border-radius: 2px;
  text-transform: uppercase;
  background-color: $base-yellow;
  width: -webkit-fill-available;
  padding: 0.6rem 0.8rem 0.6rem 0.8rem;
  margin-left: 0.8rem;
  font: $font-size-16;
  font-weight: 600;
  position: relative;

  @media (max-width: $phone-width) {
    display: flex;
  }

  .filter-dropdown {
    display: none;

    @media (max-width: $phone-width) {
      &.show {
        border: 1px solid $base-black;
        font: $font-size-16;
        color: $font-base;
        background-color: white;
        display: flex;
        gap: 0.6rem;
        text-align: left;
        width: unset;
        border: unset;
        box-shadow: unset;
        padding: 0.8rem 0.6rem;
        position: absolute;
        cursor: pointer;
        top: 44px;
        background: $base-button;
        right: 0;
        z-index: 1;
        box-shadow: 1px 2px 10px -2px;

        &.active {
          font-weight: 600;

          input:checked ~ .checkbox {
            background-color: $base-black;
          }
        }

        button {
          cursor: pointer;

          @media (max-width: $phone-width) {
            display: flex;
            flex-direction: row;
          }
        }

        .hidden {
          visibility: hidden;
        }

        input {
          visibility: hidden;
          opacity: 0;
          cursor: pointer;
          z-index: -1;
        }

        .checkbox {
          position: absolute;
          position: absolute;
          top: 2.4px;
          left: 0;
          height: 15px;
          width: 15px;
          border: 0.75px solid $base-black;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
