<template>
  <div>
    <p style="text-align:center;"><br>Your card has been succesfully sent!<br><br>To send another card, please close this window and follow your company's process to start a new order.</p>
  </div>
</template>

<script>
export default {
  name: "Thankyou"
};
</script>
