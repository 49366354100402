import axios from 'axios';

export default {
    namespaced: true,
    state: {
        reloadApp: false,
        buildVersion: null,
        buildVersionCheck: process.env.VUE_APP_BUILD_VERSION_CHECK || false,
        buildVersionCheckInterval: process.env.VUE_APP_BUILD_VERSION_CHECK_INTERVAL || 1000 * 60,
    },
    actions: {
        isUpToDate({state}) {
            return new Promise((resolve, reject) => {
                axios.get('manifest.json', {
                    headers: {
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache',
                        'Expires': '0',
                    },
                })
                .then(res => {
                    /* eslint-disable no-console */
                    if(!state.buildVersion && res.data?.version) {
                        state.buildVersion = res.data?.version
                        console.log('buildVersion='+state.buildVersion)
                    } else if (state.buildVersion && res.data?.version && state.buildVersion != res.data?.version) {
                        state.reloadApp = true
                        console.log('Build Updated. Reloading Application...')
                    } else {
                        state.reloadApp = false
                    }
                })
                .then(resolve)
                .catch(reject);
            })
        },
    }
}