<template>
  <b-form @submit.prevent="handleSubmit">
    <h2 class="text-center">Create Your Profile</h2>
    <p>Welcome, please follow the instructions below to set up your User Account.</p>
    <b-form-row>
      <b-col>
        <b-form-group
          label="Email Address"
          label-for="email"
          label-size="sm"
          label-class="mb-0 p-0"
          description="Please enter your Emaill Address to above."
        >
          <b-input
            type="email"
            name="accountRegEmail"
            id="accountRegEmail"
            placeholder="Email address"
            autofocus
            v-model="email"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group
          label="Password"
          label-for="accountRegPassword"
          label-size="sm"
          label-class="mb-0 p-0"
          description="Please follow instructions below and register your password."
        >
          <b-input
            type="password"
            name="accountRegPassword"
            id="accountRegPassword"
            placeholder="Password"
            v-model="newPassword"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group>
          <PasswordRequirements :requirements="passwordRequirements" />
        </b-form-group>
      </b-col>
    </b-form-row>

    <SubmitButton :disabled="!canSubmit">Create Account</SubmitButton>

    <SelfService />
  </b-form>
</template>

<script>
import newPassword from '@/mixins/newPassword';

import PasswordRequirements from '@/components/login/PasswordRequirements.vue';
import SelfService from '@/components/login/SelfService.vue';
import SubmitButton from '@/components/login/SubmitButton.vue';

export default {
  name: "AccountRegistrationForm",
  mixins: [
    newPassword
  ],
  components: {
    PasswordRequirements,
    SelfService,
    SubmitButton,
  },
  data() {
    return {
      email: ''
    }
  },
  computed: {
    canSubmit() {
      return this.passwordRequirementsMet;
    }
  },
  methods: {
    handleSubmit() {
      if (this.canSubmit) {
        let { email, newPassword } = this;
        email= this.email.toLowerCase();
        this.$store.dispatch('ping/registerAccount', ({
          username: email,
          email,
          password: newPassword
        }))
      }
    }
  }
};
</script>
