<template>
  <b-form @submit.prevent="handleSubmit">
    <h2 class="text-center mb-3">Account Recovery</h2>
    <b-form-row>
      <b-col>
        <b-form-group
          label="Email Address"
          label-for="passwordForgotUsername"
          label-size="sm"
          label-class="mb-0 p-0"
          description="Please enter your Email Address to recover your password."
        >
          <b-form-input
            name="passwordForgotUsername"
            id="passwordForgotUsername"
            type="email"
            placeholder="Email address"
            autocomplete="username"
            autofocus
            v-model="username"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <SubmitButton>Request Password Reset</SubmitButton>
  </b-form>
</template>

<script>
import SubmitButton from '@/components/login/SubmitButton.vue';

export default {
  name: "ForgotPasswordForm",
  components: {
    SubmitButton
  },
  data() {
    return {
      username: ''
    }
  },
  methods: {
    handleSubmit() {
      let { username } = this;
      username = this.username.toLowerCase();
      this.$store.dispatch('ping/passwordForgot', {
        username
      });
    }
  }
};
</script>
