<template>
  <LoadingOverlay />
</template>

<script>
import LoadingOverlay from '@/components/LoadingOverlay.vue';

export default {
  name: "Loading",
  components: {
    LoadingOverlay
  }
};
</script>
