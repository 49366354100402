<template>
  <b-container fluid>

    
    <!-- User Interface controls -->
    <b-row>
      <b-col md="8" class="my-1">
        <h2 class="mb-0">Order History</h2>
      </b-col>
      <b-col md="4" class="my-1">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control" placeholder="Search Orders"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
      dark
    >
      <template slot="name" slot-scope="row">{{ row.value.first }} {{ row.value.last }}</template>

      <template slot="isActive" slot-scope="row">{{ row.value ? 'Yes :)' : 'No :(' }}</template>

      <template slot="actions" slot-scope="row">
        <b-button
          size="sm"
          @click="info(row.item, row.index, $event.target)"
          class="mr-1"
        >Info modal</b-button>
        <b-button
          size="sm"
          @click="row.toggleDetails"
        >{{ row.detailsShowing ? 'Hide' : 'Show' }} Details</b-button>
      </template>

      <template slot="row-details" slot-scope="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Info modal -->
    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
      <pre>{{ infoModal.content }}</pre>
    </b-modal>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'OrderHistory',
  data() {
    return {
      items: [],
      fields: [
        { key: 'orderDate', label: 'Order Date', sortable: true, sortDirection: 'desc' },
        { key: 'orderNo', label: 'Order #', sortable: false },
        { key: 'prodName', label: 'Product Name', sortable: false },
        { key: 'sender', label: 'Sender Profile', sortable: true },
        { key: 'recipient', label: 'Recipient Name', sortable: true },
        { key: 'shipDate', label: 'Scheduled Ship', sortable: true }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapState('front', [
      'orderHistory',
    ]),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.$gtm.trackView('Order History', '');
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    updateChart(details) {
      this.items = details
      this.totalRows = details.length
    },

    triggerUpdateChart() {
      this.updateChart(this.getOrderHistoryData(this.orderHistory))
    },

    getOrderHistoryData(orderDetails) {
      const groupOrders = []
      const orderedProducts = orderDetails || []

      orderedProducts.forEach(order => {
        const prodItems = order.data.product_items || []
        const shipDate = (order.data.shipments[0].c_req_ship_date).split('-')
        prodItems.forEach((prodItem) => {
          groupOrders.push({
            orderDate: this.formatUTCtoLocal(order.data.creation_date),
            orderNo: order.data.order_no,
            prodName: prodItem.product_name,
            sender: order.data.c_senderProfile,
            recipient: order.data.shipments[0].shipping_address.full_name,
            shipDate: shipDate[1] + '/' + shipDate[2] + '/' + shipDate[0],
            status: order.data.status
          })
        })
      })

      return groupOrders
    },

    formatUTCtoLocal(utc) {
      return (new Date(utc))?.toLocaleDateString()
    },
  },

  watch: {
    orderHistory() {
      this.triggerUpdateChart()
    }
  },
}
</script>
