<template>
  <div class="layout-login d-flex flex-column" style="background:url(/images/background.jpg) no-repeat center center;background-size:cover;">
    <b-container class="my-auto">
      <b-row class="justify-content-center">
        <b-col md="7" lg="5">
          <div class="p-5 bg-white login-actions">
            <div class="text-center mb-4">
              <img src="/images/logos/hbc-logo-black-medium.png" class="d-block mx-auto img-fluid " style="width:60%;" />
            </div>

            <router-view />

            <p class="text-center mt-4"><small>Need some help? No problem. Email us at <a href="mailto:care.help@hallmarkbusinessconnections.com">care.help@hallmarkbusinessconnections.com</a>.</small></p>
          </div>
        </b-col>
      </b-row>
    </b-container>
   <Footer></Footer>  
  </div>
</template>

<script>
import Footer from "@/layouts/Footer.vue";
export default {
   components: {   
    Footer
  },
}
</script>
