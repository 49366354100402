<template>
  <b-form @submit.prevent="handleSubmit">
    <h2 class="text-center mb-3">Change Password</h2>
    <b-form-row>
      <b-col>
        <b-form-group
        label="Current Password"
        label-for="currentPassword"
        label-size="sm"
        label-class="mb-0 p-0"
        description="enter your current password above."
        >
          <b-form-input
            type="password"
            name="currentPassword"
            id="currentPassword"
            placeholder="Current Password"
            autofocus
            required
            v-model="currentPassword"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group
        label="Enter your new password"
        label-for="currentPassword"
        label-size="sm"
        label-class="mb-0 p-0"
        description="Please follow instructions below and enter a new password."
        >
          <b-input
            type="password"
            name="newPassword"
            id="newPassword"
            placeholder="New Password"
            required
            v-model="newPassword"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group>
          <PasswordRequirements :requirements="passwordRequirements" />
        </b-form-group>
      </b-col>
    </b-form-row>

    <SubmitButton :disabled="!canSubmit">Change Password</SubmitButton>
  </b-form>

</template>

<script>
import newPassword from '@/mixins/newPassword';

import PasswordRequirements from '@/components/login/PasswordRequirements.vue';
import SubmitButton from '@/components/login/SubmitButton.vue';

export default {
  name: "ChangePasswordForm",
  mixins: [
    newPassword
  ],
  components: {
    PasswordRequirements,
    SubmitButton
  },
  data() {
    return {
      currentPassword: ''
    }
  },
  computed: {
    canSubmit() {
      return this.passwordRequirementsMet;
    }
  },
  methods: {
    handleSubmit() {
      if (this.canSubmit) {
        const { currentPassword, newPassword } = this;
        this.$store.dispatch('ping/changePassword', ({
          currentPassword,
          newPassword
        }))
      }
    }
  }
};
</script>
