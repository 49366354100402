import axios from 'axios';

export default {
    namespaced: true,
    state: {
        proxyUrl: process.env.VUE_APP_PROXY_API_URL,
    },
    actions: {
        validateAddress({ getters }, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: `Address-Validate`,
                    baseURL: getters.workerUrl,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: false,
                    data: payload
                })
                .then(response => {
                    resolve(response);
                })
                .catch(reject)
            })
        },
    },
    getters: {
        workerUrl(state) {
            // state.proxyUrl = 'https://api.stage.hallmarkbusinessconnections.com/proxy/'
          return `${state.proxyUrl}sfra/`;
        },
    },
}