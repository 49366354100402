<template>
<footer class="login-footer py-4">
      <div class="care-container">
        <!-- <b-row align-v="center"> -->
          <div class="footer-logo">
            <img src="/images/logos/hbc-logo-white-small.png" class="d-block img-fluid" alt="Hallmark Business Connections">
          </div>
          <div class="text-center text-muted" cols="12" md="6">
            <p class="mb-0">&copy; Hallmark Business Connections. All rights reserved.</p>
            <p class="mb-0"><a href="mailto:Care.help@hallmarkbusinessconnections.com">Care.help@hallmarkbusinessconnections.com</a></p>
          </div>
          <div cols="12" md="3">
            <div class="d-flex justify-content-center justify-content-lg-end w-100">
              <a href="https://www.hallmarkbusiness.com/privacy-policy.html" target="_blank">Privacy &amp; Security</a>
              <a href="https://www.hallmarkbusiness.com/" target="_blank" class="ml-4">Company</a>
            </div>
          </div>
        <!-- </b-row> -->
      </div>
</footer>
</template>
<script>
export default {
  name: 'Footer'
}
</script>
<style lang="scss" scoped>  
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import '../scss/variables';
  .login-footer {
    background-color: $footer;
    a,p {
      color: #fff;
    }
    img {
      height: 45px;
      width: auto;
    }
  }
  .footer-logo {
    @media (max-width: $tablet-width) {
    display: flex;
    justify-content: center;
  }
  }
  .care-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 0.6rem;
  max-width: calc(100% - 10vw);
  margin: auto;

  @media (max-width: $tablet-width) {
    display: flex;
    flex-direction: column;
    max-width: max-content;
    flex-direction: column;
  }
}
  </style>