// Transpiler for older browsers
import 'core-js/stable';
// optional but required for transforming generator fns.
import 'regenerator-runtime/runtime'; 
import Vue from 'vue'
import './plugins/bootstrap-vue';
import App from './App.vue'
import VueGtm from "@gtm-support/vue2-gtm"
import * as Sentry from "@sentry/vue";
import store from './store'
import router from './router'

import Front from './layouts/Front.vue';
import Login from './layouts/Login.vue';
import Loading from './layouts/Loading.vue';
import Error from './layouts/Error.vue';
import Thankyou from './layouts/Thankyou.vue';

import './scss/login.scss';

Vue.component('layout-front', Front);
Vue.component('layout-login', Login);
Vue.component('layout-loading', Loading);
Vue.component('layout-error', Error);
Vue.component('layout-thankyou', Thankyou);

Vue.config.productionTip = false;

Vue.use(VueGtm, {
  id: 'GTM-W5R24WG', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  queryParams: {
    // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    // gtm_auth: '',
    // gtm_preview: '',
    // gtm_cookies_win: 'x',
  },
  defer: false,
  compatibility: false,
  nonce: '2726c7f26c',
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router, // to automatically trackview of router
  ignoredViews: [],
  trackOnNextTick: false,
});

Sentry.init({
  Vue,
  dsn: "https://3138e4d7b81b0c66f86def1fdb4dd83a@o4504753362960384.ingest.us.sentry.io/4507374724644864",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/api\.hallmarkbusinessconnections\.com/,/^https:\/\/api\.stage\.hallmarkbusinessconnections\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
