<template>
  <b-form @submit.prevent="handleSubmit">
    <h2 class="text-center mb-3">Log in to your account.</h2>

    <b-form-row>
      <b-col>
        <b-form-group
        label="Username or Email Address"
        label-for="username"
        label-size="sm"
        label-class="m-0 p-0"
        description="Enter Username or Email Address above."
        >
          <!-- <b-input-group> -->
            <b-form-input
              id="username"   
              name="username"
              placeholder="Username/Email Address"
              autocomplete="username"
              autofocus
              v-model="username"
            />
          <!-- </b-input-group> -->
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group
        label="Password"
        label-for="password"
        label-size="sm"
        label-class="mb-0 p-0"
        description="Enter Password above.">
          <b-form-input
            ref="password"
            name="password"
            type="password"
            id="password"
            placeholder="Enter Password"
            autocomplete="current-password"
            required
            v-model="password"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <SubmitButton>Log In</SubmitButton>
    <b-button href="\" block variant="light">Cancel</b-button>
    <SelfService />
  </b-form>
</template>

<script>
import { mapState } from 'vuex';
import SubmitButton from '@/components/login/SubmitButton.vue';
import SelfService from '@/components/login/SelfService.vue';

// I don't fully understand this but I was trying to use this.$nextTick to focus
// on the password field on mounted if username comes back from the Ping API,
// but it wasn't working and this seems to work:
// https://forum.vuejs.org/t/need-to-use-nexttick-twice/28341/4
function flushQueue() {
  return new Promise((resolve) => setTimeout(resolve, 0));
}

export default {
  name: "LoginForm",
  components: {
    SelfService,
    SubmitButton
  },
  data() {
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    ...mapState('ping', [
      'socialProviders'
    ]),
  },
  methods: {
    handleSubmit() {
      let { username, password } = this;
      username = this.username.toLowerCase();
      this.$store.dispatch('ping/validatePassword', ({
        username,
        password
      }))
    }
  },
  mounted() {
    const username = this.$store.state.ping.flowInfo?.data?._embedded?.user?.username;
    if (username !== 'undefined') {
      this.username = username;
      flushQueue()
        .then(() => {
          this.$refs.password.focus();
        })
    }

  }
};
</script>
