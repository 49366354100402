<template>
  <b-form @submit.prevent="handleSubmit">
    <h2 class="text-center mb-3">Account Linking</h2>
    <b-form-row>
      <b-col>
        <b-form-group
          label="Username or Email Address"
          label-for="accountLinkUsername"
          label-size="sm"
          label-class="mb-0 p-0"
          description="Please confirm your Identiy Provider E-Mail or Username"
        >
          <b-form-input
            name="accountLinkUsername"
            id="accountLinkUsername"
            placeholder="Username or E-mail Address"
            autocomplete="username"
            autofocus
            v-model="username"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <SubmitButton>Log In</SubmitButton>

  </b-form>
</template>

<script>
import SubmitButton from '@/components/login/SubmitButton.vue';

export default {
  name: "AccountLinkingForm",
  components: {
    SubmitButton
  },
  data() {
    return {
      username: '',
      password: 'ZH30c5*iY^T352M1s87o',
      
    }
  },
  methods: {
    handleSubmit() {
      let { username, password } = this;
      username = this.username.toLowerCase();
      this.$store.dispatch('ping/accountLink', {
        username,
        password
      })
    }
  },
  mounted(){
    if (this.$store.state.ping.accountLinkUsername && this.$store.state.ping.accountLinkUsername!==""){
      this.username = this.$store.state.ping.accountLinkUsername.toLowerCase();
      this.handleSubmit();
    }
  }
};
</script>
