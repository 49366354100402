<template>
  <div class="layout-login d-flex flex-column" :style="styles">
    <b-container class="my-auto">
      <b-row class="justify-content-center">
        <b-col md="7" lg="5">
          <div class="p-5 bg-white login-actions">
            <div class="text-center mb-4">
              <img :src="`/images/logos/${config.logo}`" class="d-block mx-auto img-fluid" />
            </div>

            <router-view />

            <p class="text-center mt-4"><small>Need some help? No problem. Email us at <a href="mailto:care.help@hallmarkbusinessconnections.com">care.help@hallmarkbusinessconnections.com</a>.</small></p>
          </div>
        </b-col>
      </b-row>
    </b-container>
   <Footer></Footer>  
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Footer from "@/layouts/Footer.vue";
export default {
   components: {   
    Footer
  },
  computed: {
    ...mapState('ping', [
      'config'
    ]),
    styles() {
      let styles = '';
      if (this.config.background_image) {
        styles += `background-image:url('/images/${this.config.background_image}');`;
      }
      if (this.config.primary_color) {
        styles += `--loginprimary: ${this.config.primary_color};`;
      }
      if (this.config.primary_color_hover) {
        styles += `--loginprimaryhover: ${this.config.primary_color_hover};`;
      } else if (this.config.primary_color) {
        styles += `--loginprimaryhover: ${this.config.primary_color};`;
      }
      return styles;
    }
  },
}
</script>
