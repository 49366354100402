/* eslint-disable no-console */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Error from '../views/Error.vue';
import Thankyou from '../views/Thankyou.vue';
import store from '../store/index';
import Cookies from 'js-cookie';
import clientConfig from '../util/clientConfig';

function beforeProtected(to, from, next) {
  store.commit('startLoading');

  const client = Cookies.get('client', {
    cookieDomain: store.state.ping.cookieDomain
  });
  
  store.dispatch('ping/setConfig', client)
    .then(() => store.dispatch('ping/checkAuthentication', to))
    .then(() => {
      if (store.state.ping.authenticated) {
        const activeCategory = Cookies.get('activeCategory', {
          cookieDomain: store.state.ping.cookieDomain
        });
        if (activeCategory) {
          store.commit('front/setActiveCategory', activeCategory);
          Cookies.remove('activeCategory', { cookieDomain: store.state.ping.cookieDomain });
        }

        const recipient = {
          customer_id: to.query?.customerId || store.getters['ping/parsedIdToken']?.accountNumber|| '',
          shipping_address: {
            address1: to.query?.address1 || store.getters['ping/parsedIdToken']?.recipientAddress1 || '',
            address2: to.query?.address2 || store.getters['ping/parsedIdToken']?.recipientAddress2 || '',
            city: to.query?.city || store.getters['ping/parsedIdToken']?.recipientCity || '',
            company_name: '',
            country_code: to.query?.countryCode || store.getters['ping/parsedIdToken']?.recipientCountry || '',
            first_name: to.query?.firstName || store.getters['ping/parsedIdToken']?.recipientFirstName || '',
            last_name: to.query?.lastName || store.getters['ping/parsedIdToken']?.recipientLastName || '',
            phone: '',
            postal_code: to.query?.postalCode || store.getters['ping/parsedIdToken']?.recipientPostalCode || '',
            state_code: to.query?.state || store.getters['ping/parsedIdToken']?.recipientState || '',
          }
        };
        store.commit('front/setRecipient', recipient);
        return store.dispatch('front/resolveCustomer');
      } else {
        // missing accessToken or idToken for this individual
        console.log('No token found in URL Fragment');

        // was the user just sent here from p14c?
        if (store.state.ping.flowId) {

          // yes, url has a flowId - so they just arrived from p14c
          console.log('flowId found in url');
          console.log('Performing p14c ajax query to determine next step');
          return store.dispatch('ping/checkFlowStatus');

        } else {

          // no, url does not have a flowId.  and we don't have a session.  send them authorizationUrl
          console.log('No flowId found in url');
          console.log('Redirecting to p14c to bootstrap session');
          store.dispatch('ping/doLogin');

        }
      }
    })
    .then(() => {
      store.commit('stopLoading');
      next();
    })
    .catch(error => {
      // console.error(error);
      store.commit('stopLoading');
      if (error?.locked) {
        store.dispatch('handleError', {
          title: 'Account Disabled',
          message: 'Your account has been locked out due to inactivity.  Please reach out to your client administrator to have your account unlocked.',
          buttonTitle: 'Try Again',
          buttonRouter: '/',
        });
      } else if (error?.disabled) {
        store.dispatch('handleError', {
          title: 'Account Disabled',
          message: 'You account is disabled. Please reach out to your client administrator to have your account enabled.',
        });
      } else if (!store.state.ocapi.customerGroup) {
        store.dispatch('handleError', {
          title: 'Access Denied',
          message: 'You are not authorized to view this page.',
          buttonTitle: 'Try Again',
          buttonRouter: '/',
        });
      } else {
        store.dispatch('handleError', {
          title: 'Error logging in',
          message: 'An error occurred during login',
          buttonTitle: 'Try Again',
          buttonRouter: '/',
          data: error
        })
      }
    })
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      const shipDate = to.query?.shippingDate || '';
      store.commit('front/setDefaultShipDate', shipDate);
      if (to.query?.cgid) {
        Cookies.set('activeCategory', to.query.cgid, { cookieDomain: store.state.ping.cookieDomain });
      }
      beforeProtected(to, from, next);
    }
  },
  {
    path: '/login',
    name: 'Log in',
    component: Login,
    meta: {
      layout: 'login'
    },
    beforeEnter: beforeProtected
  },
  // create client login routes dynamically
  ...Object.keys(clientConfig).map(id => ({
    path: `/${id}`,
    name: `Log in (${id})`,
    component: Login,
    meta: {
      layout: 'login'
    },
    beforeEnter: (to, from, next) => {
      Cookies.set('client', id, { cookieDomain: store.state.ping.cookieDomain });
      beforeProtected(to, from, next);
    }
  })),
  {
    path: '/sso/:id',
    name: 'SSO log in (client)',
    component: Login,
    meta: {
      layout: 'login'
    },
    beforeEnter: (to, from, next) => {
      Cookies.set('client', to.params.id, { cookieDomain: store.state.ping.cookieDomain });
      Cookies.set('autoredirect', 'true', { cookieDomain: store.state.ping.cookieDomain });
      beforeProtected(to, from, next);
    }
  },
  {
    path: '/logout',
    name: 'Log out',
    beforeEnter: () => {
      store.dispatch('ping/doLogout');
    }
  },
  {
    path: '/error',
    name: 'Error',
    component: Error,
    meta: {
      layout: 'error'
    },
  },
  {
    path: '/thankyou',
    name: 'Thank You',
    component: Thankyou,
    meta : {
      layout: 'thankyou'
    },
    beforeEnter: (to, from, next) => {
      store.commit('stopLoading');
      next();
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router
