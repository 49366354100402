<template>
  <div>
    <h4 class="text-secondary text-uppercase">Error</h4>
    <h1 class="text-danger">{{ error.title }}</h1>
    <p class="text-white lead">{{ error.message }}</p>
    <pre v-if="error.data" class="bg-light d-block p-3"><code v-html="JSON.stringify(error.data, null, 2)"></code></pre>
    <b-button v-if="error.buttonTitle" :to="error.buttonRouter">{{ error.buttonTitle }} </b-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "Error",
  computed: {
    ...mapState([
      'error'
    ]),
  },
};
</script>
