<template>
  <div class="product" :id="`product-${card.product_id}`" :listIndex="index">
    <button class="heart-btn" @click="toggleFavorite(card.product_id)">
      <b-icon class="heart"
        :icon="isFavorite(card.product_id) ? 'heart-fill' : 'heart'"></b-icon>
    </button>

    <div class="product-image" @click="onClick" v-bind:style="{ backgroundImage: 'url(' + card.image.link + ')' }">
    </div>
    <h3 class="text-center productTitleClick" @click="onClick">{{ card.product_name }}</h3>
    <span class="mt-1">#{{ card.product_id }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "SelectCard",
  props: {
    card: Object,
    index: Number
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('front', [
      'isFavorite'
    ])
  },
  methods: {
    onClick(e) {
      this.$gtm.trackEvent({
        event: 'view_item',
        category: (this.$store.state.front.activeCategory == "root") ? "view-all" : this.$store.state.front.activeCategory,
        action: 'click',
        label: this.card.product_name,
        value: 1,
        noninteraction: false,
      });
      this.$emit('click', e);
    }, toggleFavorite(product_id) {
      this.$store.dispatch("ping/toggleFavorite", product_id);
    },
  },


};
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import '../scss/variables';

.product-image {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.productTitleClick {
  margin-top: 0.6rem;
  margin-bottom: 0;
  font: $font-size-18;
  color: $font-base;
  cursor: pointer;
}

.product {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  .heart-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    right: -12px;
    top: -2px;
    z-index: 2;
    margin: 0;

    .heart {
      color: $base-pink;
      width: 2rem;
      height: 1.75rem;

      @media (max-width: $tablet-width) {
        width: 1.4rem;
        height: 1.25rem;
      }
    }
  }
}

span {
  font: $font-size-14;
  color: $font-base;
}
</style>