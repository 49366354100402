<template>
  <div class="overlay">
    <div class="spinner-wrapper">
      <b-spinner variant="info" style="width:3rem; height:3rem;">
      </b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingOverlay'
}
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#fff, .5);
  z-index: 1041;

  .spinner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
