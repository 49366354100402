<template>
  <div>

    <div v-if="loading" class="d-flex justify-content-center">
      <b-spinner />
    </div>

    <template v-else>
      <p class="text-danger" v-for="error in loginErrors" :key="error.message">{{ error.message }}
        <span v-for="inner in error.innerError" :key="inner">
          <br/>- {{ inner }}
        </span>
      </p>
      <template v-if="view === 'DEFAULT'">
        <LoginForm v-if="status === 'USERNAME_PASSWORD_REQUIRED' || status === 'PASSWORD_REQUIRED'" />
        <IdentifierForm v-if="status === 'SIGN_ON_REQUIRED'" />
        <UserVerificationForm v-if="status === 'VERIFICATION_CODE_REQUIRED'" />
        <OtpForm v-if="status === 'OTP_REQUIRED'" />
        <ChangePasswordForm v-if="status === 'MUST_CHANGE_PASSWORD'" />
        <AccountRecoveryForm v-if="status === 'RECOVERY_CODE_REQUIRED'" />
        <DeviceSelect v-if="status === 'DEVICE_SELECTION_REQUIRED'" />
        <AccountLinkingForm v-if="status === 'ACCOUNT_LINKING_REQUIRED'" />
        <DevicePush v-if="status === 'PUSH_CONFIRMATION_REQUIRED'" />
      </template>
      <ForgotPasswordForm v-else-if="view === 'FORGOT_PASSWORD'" />
      <AccountRegistrationForm v-else-if="view === 'ACCOUNT_REGISTRATION'" />
    </template>


    <!-- self service area -->

    <b-container v-if="warningTitle.length || warningMessage.length">
      <h2>{{ warningTitle }}</h2>
      <p class="lead">{{ warningMessage }}</p>
    </b-container>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import LoginForm from '@/components/login/LoginForm.vue';
import IdentifierForm from '@/components/login/IdentifierForm.vue';
import UserVerificationForm from '@/components/login/UserVerificationForm.vue';
import OtpForm from '@/components/login/OtpForm.vue';
import ChangePasswordForm from '@/components/login/ChangePasswordForm.vue';
import ForgotPasswordForm from '@/components/login/ForgotPasswordForm.vue';
import AccountRegistrationForm from '@/components/login/AccountRegistrationForm.vue';
import AccountRecoveryForm from '@/components/login/AccountRecoveryForm.vue';
import DeviceSelect from '@/components/login/DeviceSelect.vue';
import AccountLinkingForm from '@/components/login/AccountLinkingForm.vue';
import DevicePush from '@/components/login/DevicePush.vue';

export default {
  name: "Login",
  components: {
    LoginForm,
    IdentifierForm,
    UserVerificationForm,
    OtpForm,
    ChangePasswordForm,
    ForgotPasswordForm,
    AccountRegistrationForm,
    AccountRecoveryForm,
    DeviceSelect,
    AccountLinkingForm,
    DevicePush,
  },
  data() {
    return {
      redirect_uri: 'https://127.0.0.1:8080/login/',
    }
  },
  computed: {
    ...mapState('ping', [
      'loading',
      'flowId',
      'warningTitle',
      'warningMessage',
      'view',
      'loginErrors'
    ]),
    status() {
      // return 'SIGN_ON_REQUIRED'
      return this.$store.state.ping.flowInfo?.data?.status;
    }
  },
  methods: {
    ...mapActions('ping', [
      'logIn',
      'fetchAppToken',
      'readFlow',
      'checkUsernamePassword',
      'signOnWithUsername',
      'resetPassword'
    ]),
  },
  mounted() {
    if (this.$store.state.ping.authenticated) {
      this.$router.push('/');
    }
  }
};

</script>
