import pConfig from '../util/personalizationConfig';
import axios from 'axios';

function ensureTrailingSlash(u) {
  const url = new URL(u);
  url.pathname += url.pathname.endsWith("/") ? "" : "/";
  return url.toString();
}

function generateLayers(layers, panel, config, sideOfCard) {
  const {
    cardSize,
    orientation
  } = config;

  panel?.images?.forEach((image, index) => {
    if (image.url) {
      layers.push({
        type: 'image',
        url: image.url,
        XCordinate: pConfig.constants[cardSize][orientation][sideOfCard].images[index].x,
        YCordinate: pConfig.constants[cardSize][orientation][sideOfCard].images[index].y,
        ContentWidth: pConfig.constants[cardSize][orientation][sideOfCard].images[index].w,
        ContentHeight: pConfig.constants[cardSize][orientation][sideOfCard].images[index].h,
        Scale: false,
        Print: false,
        applyNoGap: false,
        flipContent: (sideOfCard === 'outside' && orientation === 'H') ? true : false
      });
    }
  });

  panel?.messages?.forEach((message, index) => {
    // if (message.text) {
      layers.push({
          type: 'text',
          text: message.text || ' ',
          XCordinate: pConfig.constants[cardSize][orientation][sideOfCard].messages[index].x,
          YCordinate: pConfig.constants[cardSize][orientation][sideOfCard].messages[index].y,
          ContentWidth: pConfig.constants[cardSize][orientation][sideOfCard].messages[index].w,
          ContentHeight: pConfig.constants[cardSize][orientation][sideOfCard].messages[index].h,
          Font: pConfig[message.font],
          Scale: true,
          Print: false,
          applyNoGap: false,
          flipContent: (sideOfCard === 'outside' && orientation === 'H') ? true : false
      });
    // }
  });

  panel?.barcodes?.forEach((barcode, index) => {
    // if (message.text) {
      layers.push({
          type: 'barcode',
          text: barcode.text || 'testing1234',
          XCordinate: pConfig.constants[cardSize][orientation][sideOfCard].barcodes[index].x,
          YCordinate: pConfig.constants[cardSize][orientation][sideOfCard].barcodes[index].y,
          ContentWidth: pConfig.constants[cardSize][orientation][sideOfCard].barcodes[index].w,
          ContentHeight: pConfig.constants[cardSize][orientation][sideOfCard].barcodes[index].h,
          Scale: false,
          Print: false,
          applyNoGap: false,
          BarcodeType: "datamatrix",
      });
    // }
  });
}

function callGetImage(baseURL, payload) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: '/getImage',
      baseURL: baseURL,
      data: payload,
      withCredentials: false
    })
      .then(resolve)
      .catch(reject);
  });
}

export default {
  namespaced: true,
  state: {
    personalizationApiUrl: process.env.VUE_APP_PERSONALIZATION_API_URL,
  },
  actions: {
    personalizeCard({ state }, payload) {
      let configDefaults = {
        render: 'preview',
        cardSize: null,
        personalization: [{
          side: null,
          backgroundTemplate: null,
          rightOrBottomOfCard: {
            images: [],
            messages: []
          },
          leftOrTopOfCard: {
            images: [],
            messages: []
          }
        }]
      };
      let config = Object.assign({}, configDefaults, payload);

      var pages = [];
      config.personalization?.forEach(page => { // inside or outside page of the card
        let layers = []
        // left/top side of page - personalization currently not enabled. Set coordinates in personalizationConfig when enabling
        // generateLayers(layers, page.leftOrTopOfPage, config, page.sideOfCard);
        generateLayers(layers, page.rightOrBottomOfPage, config, page.sideOfCard);
        if (layers.length) {
          pages.push({
            background: {
              url: page.pageTemplate,
              print: true
            },
            layers: layers
          });
        }
      })

      var data = {
        cardSize: '',
        cardWidth: pConfig.constants[config.cardSize][config.orientation].card.w,
        cardHeight: pConfig.constants[config.cardSize][config.orientation].card.h,
        orientation: config.orientation,
        render: config.render,
        pages: pages,
        bleed: config.bleed || 0,
        singlePanelCrop: config.singlePanelCrop || false,
        GetSurfaceImages: config.GetSurfaceImages || false,
        forceVerticalOrientation: (config.render == "print" && config.orientation == "H") ? true : false
      }

      return new Promise(function (resolve, reject) {
        callGetImage(state.personalizationApiUrl, data)
        .then(resolve)
        .catch(reject);
      });
      
    },
    previewEnvelope({ state }, payload) {
      return new Promise((resolve, reject) => {
        var data = {
          cardSize: '',
          cardWidth: 1280,
          cardHeight: 1041,
          orientation: 'H',
          render: 'preview',
          pages: [
            {
              background: {
                url: process.env.VUE_APP_ENVELOPE_IMAGE_URL || `${ensureTrailingSlash(process.env.VUE_APP_LANDING_URL)}images/envelope.jpg`,
                print: true
              },
              layers: [
                // return address
                {
                  type: 'text',
                  XCordinate: 400,
                  YCordinate: 437,
                  ContentWidth: 325,
                  ContentHeight: 150,
                  Scale: true,
                  Print: false,
                  applyNoGap: false,
                  Font: {
                    fontAlignment: 'left',
                    fontColor: '#000000',
                    fontSize: 5.5,
                    fontName: 'JohnsonScriptHMK',
                    fontStyle: 'regular'
                  },
                  text: payload.returnAddress
                },
                // recipient address
                {
                  type: 'text',
                  XCordinate: 640,
                  YCordinate: 680,
                  ContentWidth: 400,
                  ContentHeight: 200,
                  Scale: true,
                  Print: false,
                  applyNoGap: false,
                  Font: {
                    fontAlignment: 'left',
                    fontColor: '#000000',
                    fontSize: 7,
                    fontName: 'JohnsonScriptHMK',
                    fontStyle: 'regular'
                  },
                  text: payload.recipientAddress
                },
              ]
            }
          ]
        };

        callGetImage(state.personalizationApiUrl, data)
        .then(resolve)
        .catch(reject);
      });
    },
    renderEnvelope({ state }, payload) {
      return new Promise((resolve, reject) => {
        var data = {
          cardSize: '',
          cardWidth: 2161,
          cardHeight: 1500,
          orientation: 'H',
          render: 'print',
          convertImageToGrayscale: true,
          pages: [
            {
              background: {
                "url":"",
                "print":false
              },
              layers: [
                // return address
                {
                  type: 'text',
                  XCordinate: 90,
                  YCordinate: 80,
                  ContentWidth: 600,
                  ContentHeight: 300,
                  Scale: true,
                  Print: false,
                  applyNoGap: false,
                  Font: {
                    fontAlignment: 'left',
                    fontColor: '#000000',
                    fontSize: 14,
                    fontName: 'JohnsonScriptHMK',
                    fontStyle: 'regular'
                  },
                  text: payload.returnAddress
                },
                // recipient address
                {
                  type: 'text',
                  XCordinate: 681,
                  YCordinate: 590,
                  ContentWidth: 800,
                  ContentHeight: 350,
                  Scale: true,
                  Print: false,
                  applyNoGap: false,
                  Font: {
                    fontAlignment: 'left',
                    fontColor: '#000000',
                    fontSize: 14,
                    fontName: 'JohnsonScriptHMK',
                    fontStyle: 'regular'
                  },
                  text: payload.recipientAddress
                },
                //scan code
                {
                  "type": "barcode",
                  "text": payload.scanCode,
                  "XCordinate": 890,
                  "YCordinate": 65,
                  "ContentWidth": 160,
                  "ContentHeight": 160,
                  "Scale": false,
                  "Print": true,
                  "applyNoGap": false,
                  "BarcodeType": "datamatrix"
              }
              ]
            }
          ]
        };

        callGetImage(state.personalizationApiUrl, data)
        .then(resolve)
        .catch(reject);
      });
    }
  }
};
