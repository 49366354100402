<template>
  <b-list-group>
    <b-list-group-item
      v-for="requirement in requirements"
      :key="requirement.text"
      class="d-flex justify-content-between align-items-center password-requirements">
      <small>{{ requirement.text }}</small>
      <b-icon icon="check-circle-fill" variant="success" v-if="requirement.met"></b-icon>
      <b-icon icon="check-circle" variant="secondary" v-if="!requirement.met"></b-icon>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  name: "PasswordRequirements",
  props: [
    'requirements'
  ]
}
</script>
