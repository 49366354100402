<template>
 <div>
 <b-form @submit.prevent="handleSubmit">
  <h2 class="text-center mb-3">Log in to your account.</h2>

  <b-form-row>
    <b-col>
      <b-form-group
        label="Email address"
        label-for="identifier"
        label-size="sm"
        label-class="font-weight-light mb-0 p-0">
        <b-form-input
          id="identifier"
          name="identifier"
          placeholder="Enter Email address"
          autocomplete="identifier"
          autofocus
          v-model="username" />
      </b-form-group>
    </b-col>
  </b-form-row>

  <SubmitButton>Log In</SubmitButton>
</b-form>
</div>
</template>

<script>
import SubmitButton from '@/components/login/SubmitButton.vue';
import { mapState } from 'vuex';
export default {
   computed: {
    ...mapState('ping', [
      'config'
    ]) 
  },
  name: "IdentifierForm",
  components: {
    SubmitButton
  },
  data() {
    return {
      username: ''      
    }
  },
   mounted() {
    
  },
  methods: {    
    handleSubmit() {
      let {
        username
      } = this;
      username = username.toLowerCase();
      this.$store.dispatch('ping/validateIdentifier', {
        username
      });
    }
  }
};
</script>