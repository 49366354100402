const regexLower = new RegExp('(?=.*[a-z])');
const regexUpper = new RegExp('(?=.*[A-Z])');
const regexNumeric = new RegExp('(?=.*[0-9])');
// eslint-disable-next-line no-useless-escape
const regexSpecial = new RegExp('(?=.*[~!@#\$%\^&\*\)\(\|\;\:\,\.\?\_\-])');
const regexLength = new RegExp('(?=.{8,})');

const newPassword = {
  data() {
    return {
      newPassword: ''
    }
  },
  computed: {
    passwordRequirements() {
      return [
        {
          text: 'The password must be at least 8 characters in length',
          met: regexLength.test(this.newPassword)
        },
        {
          text: 'The password must have one or more upper case letters',
          met: regexUpper.test(this.newPassword)
        },
        {
          text: 'The password must have one or more lower case letters',
          met: regexLower.test(this.newPassword)
        },
        {
          text: 'The password must have one or more number',
          met: regexNumeric.test(this.newPassword)
        },
        {
          text: 'The password must have one or more special characters',
          met: regexSpecial.test(this.newPassword)
        }
      ];
    },
    passwordRequirementsMet() {
      return this.passwordRequirements.filter(requirement => !requirement.met).length === 0;
    }
  }
}

export default newPassword;
