var sentimentWidth = 1248;
var sentimentHeight = 534;
var size60 = {
  card: {
    w: 3188,
    h: 1594,
  },
  images: [
    {
      w: sentimentWidth,
      h: sentimentHeight,
      x: 1837,
      y: 247,
    },
    {
      w: 1218,
      h: 300,
      x: 1782,
      y: 1222,
    }
  ],
  messages: [
    {
      w: 1218,
      h: 413,
      x: 1782,
      y: 763,
    }
  ]
};

export default {

  // Fonts
  garamond10Center: {
    fontAlignment: 'center',
    fontColor: '#000000',
    fontSize: 10,
    fontName: 'Garamond',
    fontStyle: 'regular'
  },
  johnson14Left: {
    fontAlignment: 'left',
    fontColor: '#000000',
    fontSize: 14,
    fontName: 'JohnsonScriptHMK',
    fontStyle: 'regular'
  },
  tradegothic11Center: {
    fontAlignment: 'center',
    fontColor: '#000000',
    fontSize: 11,
    fontName: 'Trade Gothic HMK',
    fontStyle: 'regular'
  },
  whitneybook11Center: {
    fontAlignment: 'center',
    fontColor: '#000000',
    fontSize: 11,
    fontName: 'Whitney Book',
    fontStyle: 'regular'
  },

  constants: {
    '49': {
      H: {
        card: {
          w: 2081,
          h: 2888,
        },
        images: [
          {
            w: sentimentWidth,
            h: sentimentHeight,
            x: 483,
            y: 1620,
          },
          {
            w: 1800,
            h: 300,
            x: 141,
            y: 2511,
          }
        ],
        messages: [
          {
            w: 1705,
            h: 338,
            x: 190,
            y: 2136,
          }
        ]
      },
      V: {
        card: {
          w: 2888,
          h: 2081,
        },
        images: [
          {
            w: sentimentWidth,
            h: sentimentHeight,
            x: 1609,
            y: 485,
          },
          {
            w: 1068,
            h: 300,
            x: 1632,
            y: 1705,
          }
        ],
        messages: [
          {
            w: 1068,
            h: 656,
            x: 1632,
            y: 1002,
          }
        ]
      }
    },
    '60': {
      H: size60,
      S: size60
    },
    '70': {
      H: {
        card: {
          w: 2419,
          h: 3338,
        },
        images: [
          {
            w: sentimentWidth,
            h: sentimentHeight,
            x: 657,
            y: 1955,
          },
          {
            w: 2100,
            h: 338,
            x: 165,
            y: 2922,
          }
        ],
        messages: [
          {
            w: 2043,
            h: 413,
            x: 192,
            y: 2471,
          }
        ]
      },
      V: {
        card: {
          w: 3338,
          h: 2419,
        },
        images: [
          {
            w: sentimentWidth,
            h: sentimentHeight,
            x: 1954,
            y: 649,
          },
          {
            w: 1293,
            h: 375,
            x: 1857,
            y: 1973,
          }
        ],
        messages: [
          {
            w: 1293,
            h: 656,
            x: 1857,
            y: 1167,
          }
        ]
      }
    },
    '831': {
      H: {
        card: {
          w: 2114,
          h: 2870,
        },
        inside: {
          images: [
            {
              w: sentimentWidth,
              h: sentimentHeight,
              x: 433,
              y: 1561,
            },
            {
              w: 1948,
              h: 295,
              x: 112,
              y: 2900,
            }
          ],
          messages: [
            {
              w: 1614,
              h: 550,
              x: 250,
              y: 2160,
            }
          ]
        },
        outside: {
          images: [
            {
              w: 1888,
              h: 338,
              x: 113,
              y: 2375,
            }
          ],
          messages: [
            {
              w: 1888,
              h: 75,
              x: 113,
              y: 2370,
            },
            {
              w: 1875,
              h: 352,
              x: 113,
              y: 2085,
            }
          ],
          barcodes: [
            {
              w: 125,
              h: 125,
              x: 1880,
              y: 1210
            }
          ]
        }
      },
      V: {
        card: {
          w: 2870,
          h: 2114,
        },
        inside: {
          images: [
            {
              w: sentimentWidth,
              h: sentimentHeight,
              x: 1510,
              y: 326,
            },
            {
              w: 1324,
              h: 200,
              x: 1542,
              y: 1787,
            }
          ],
          messages: [
            {
              w: sentimentWidth,
              h: 680,
              x: 1510,
              y: 1057,
            }
          ]
        },
        outside: {
          images: [
            {
              w: 1248,
              h: 338,
              x: 113,
              y: 1460,
            }
          ],
          messages: [
            {
              w: 1248,
              h: 75,
              x: 113,
              y: 1410,
            },
            {
              w: 1173,
              h: 435,
              x: 140,
              y: 950,
            }
          ],
          barcodes: [
            {
              w: 125,
              h: 125,
              x: 1210,
              y: 110
            }
          ]
        }
      }
    }
  },
};
