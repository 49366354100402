<template>
  <b-form @submit.prevent="handleSubmit">
    <h2 class="text-center mb-3">Multifactor Authentication</h2>
    <b-form-row>
      <b-col>
        <b-form-group
          class="mb-4"
          label="One Time Passcode"
          label-for="identifier"
          label-size="sm"
          label-class="mb-0 p-0"
          description="Please Check your Multifactor Authenticator to verify your account."
          >
          <b-form-input
            type="text"
            name="otp"
            id="otp"
            placeholder="Enter One Time Passcode"
            autofocus
            required
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <SubmitButton>Log In</SubmitButton>
  </b-form>
</template>

<script>
import SubmitButton from '@/components/login/SubmitButton.vue';

export default {
  name: "OtpForm",
  components: {
    SubmitButton
  },
  data() {
    return {
      otp: ''
    }
  },
  methods: {
    handleSubmit() {
      const { otp } = this;
      this.$store.dispatch('ping/validateOtp', {
        otp
      });
    }
  }
};
</script>
