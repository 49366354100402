<template>
  <b-form @submit.prevent="handleSubmit">
    <h2 class="text-center">Account Recovery</h2>
    <p>If you do not have an Account or Email, please contact your administrator to recover your password.</p>
    <b-form-row>
      <b-col>  
        <b-form-group
        label="Recovery Code"
        label-for="passwordRecoverCode"
        label-size="sm"
        label-class="mb-0 p-0"
          description="If you have an active account with a valid email address, you will receive an email with a recovery code."
        >
          <b-form-input
            type="text"
            name="passwordRecoverCode"
            id="passwordRecoverCode"
            placeholder="Recovery Code"
            autofocus
            required
            v-model="recoveryCode"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group
          label="New Password"
          label-for="passwordRecoverNewPassword"
          label-size="sm"
          label-class="mb-0 p-0"
          description="Please follow instructions below and enter a new password."
        >
          <b-form-input
            type="password"
            name="passwordRecoverNewPassword"
            id="passwordRecoverNewPassword"
            placeholder="New Password"
            required
            v-model="newPassword"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group>
          <PasswordRequirements :requirements="passwordRequirements" />
        </b-form-group>
      </b-col>
    </b-form-row>

    <SubmitButton :disabled="!this.canSubmit">Save</SubmitButton>
  </b-form>
</template>

<script>
import newPassword from '@/mixins/newPassword';

import PasswordRequirements from '@/components/login/PasswordRequirements.vue';
import SubmitButton from '@/components/login/SubmitButton.vue';

export default {
  name: "AccountRecoveryForm",
  mixins: [
    newPassword
  ],
  components: {
    PasswordRequirements,
    SubmitButton
  },
  data() {
    return {
      recoveryCode: '',
      newPassword: '',
    }
  },
  computed: {
    canSubmit() {
      return this.passwordRequirementsMet && this.recoveryCode.length > 1;
    }
  },
  methods: {
    handleSubmit() {
      const { recoveryCode, newPassword } = this;
      this.$store.dispatch('ping/passwordRecover', {
        recoveryCode,
        newPassword
      });
    }
  }
};
</script>
