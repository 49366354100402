<template>
  <b-form @submit.prevent="handleSubmit">
    <h2 class="text-center mb-3">Thank You!</h2>
    <b-form-row>
      <b-col>
        <b-form-group
        class="mb-4"
        label="Verification Code"
        label-for="verificationCode"
        label-size="sm"
        label-class="mb-0 p-0"
        description="We've sent a verification email to your email address. Please verify your email to finish setting up your account."
        >
          <b-input
            type="text"
            name="Verification Code"
            id="verificationCode"
            placeholder="Enter Verification Code"
            autofocus
            required
            v-model="verificationCode"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <SubmitButton>Verify</SubmitButton>
  </b-form>
</template>

<script>
import SubmitButton from '@/components/login/SubmitButton.vue';

export default {
  name: "UserVerificationForm",
  components: {
    SubmitButton
  },
  data() {
    return {
      verificationCode: ''
    }
  },
  methods: {
    handleSubmit() {
      const { verificationCode } = this;
      this.$store.dispatch('ping/userVerify', {
        verificationCode
      });
    }
  }
};
</script>
