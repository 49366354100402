<template>
  <div>
    <h2 class="text-center mb-3">Select Device</h2>
    <b-btn-group class="d-flex flex-column align-items-center" style="gap:0.75rem">
      <b-button
        v-for="device in selectableDevices"
        :key="device.id"
        variant="primary"
        style="border-radius: 8px;"
        @click.prevent="handleDeviceClick(device.id)"
      >
        {{ labelFromDevice(device) }}
      </b-button>
    </b-btn-group>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "DeviceSelect",
  computed: {
    ...mapState('ping', [
      'selectableDevices'
    ])
  },
  methods: {
    handleDeviceClick(id) {
      this.$store.dispatch('ping/deviceSelect', {
        id
      });
    },
    labelFromDevice(device) {
      switch (device.type) {
        case 'EMAIL':
          return `EMAIL (${device.email})`;
        case 'SMS':
          return `SMS (${device.phone})`;
        case 'MOBILE':
          return `PUSH (${device.model.marketingName})`;
      }
      return device.id;
    }
  }
};
</script>
